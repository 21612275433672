<template>
    <div>
        PAGE NOT FOUND!
    </div>
</template>

<script>
export default {
    name: "error"
}
</script>

<style scoped>

</style>